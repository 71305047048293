import React, { useState, useRef, useMemo, useEffect } from "react";
import JoditEditor from "jodit-react";

const Editor = ({ value, onChange, readOnly = false }) => {
  const editor = useRef(null);
  const [content, setContent] = useState("");

  useEffect(() => {
    if(!readOnly) { 
      onChange(content);
    }
  }, [content]);

  const config = useMemo(
    () => ({
      readonly: readOnly,
      placeholder: "Start typings...",
      height: 600,
      toolbarSticky: false,
      uploader: {
        insertImageAsBase64URI: true,
      },
    }),
    [readOnly]
  );

  return (
    <JoditEditor
      ref={editor}
      value={value || content}
      config={config}
      tabIndex={1}
      onBlur={(newContent) => setContent(newContent)}
    />
  );
};

export default Editor;
